<script setup lang="ts">
type Prizes = {
	coins?: number;
	entries?: number;
	freeSpinCount?: number;
};

const { t } = useT();

const props = defineProps<{
	levels: number;
	activeLevel: number;
	durationLeft: string;
	isAvailable: boolean;
	hideLabel?: boolean;
	currentPrize?: Prizes;
	totalPrizes?: Prizes;
	isCompleted?: boolean;
	gameId?: string;
	isPlayerFreeSpin?: boolean;
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { all } = useGamesState();
const freeSpins = useFreeSpins();

const freeSpinGame = computed(() => all.value?.[Number(props.gameId ?? 0)]);
const imageSrcPath = computed(() => `${baseImageUrl}${freeSpinGame.value?.img || "/nuxt-img/card-games/pattern.webp"}`);
const isLastPrize = computed(() => props.levels === props.activeLevel);

const subTitle = computed(() => {
	if (props.isCompleted) {
		return t("Congrats, you've collected all rewards!");
	}

	if (isLastPrize.value && props.isAvailable) {
		return t("Congrats! Get a Mega Prize on the 7th day");
	}
	return props.isAvailable ? t("Get prizes every day") : t("Come back tomorrow for the next prize!");
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 135,
			height: 246,
			format: 'avif',
			loading: 'lazy',
			src: `/nuxt-img/daily-login/promo${isPlayerFreeSpin ? '-free' : ''}.png`,
			alt: 'daily-login-promotion-img'
		}"
		width="328px"
		height="246px"
		:class="['card-daily', { completed: isPlayerFreeSpin, lastDay: isLastPrize && isAvailable }]"
	>
		<NuxtImg
			v-if="isPlayerFreeSpin"
			:src="imageSrcPath"
			class="free-game"
			width="440"
			height="440"
			alt="free plays prize"
			format="avif"
			loading="lazy"
		/>
		<div class="top">
			<div class="free">
				<ASvg name="48/free" class="icon" />
				<AText class="text-tlalnepantla free-text text-carabanchel" :modifiers="['semibold', 'uppercase']">
					{{ t("Free") }}
				</AText>
			</div>
			<AText v-if="isPlayerFreeSpin" class="title" variant="topeka" :modifiers="['bold', 'uppercase']">
				{{ t("Enter the game and use your") }}
			</AText>
			<template v-else>
				<AText :modifiers="['semibold', 'uppercase']">
					{{ t("Daily Reward") }}
				</AText>
				<AText class="subtitle" variant="topeka" :modifiers="['semibold']">
					{{ subTitle }}
				</AText>
			</template>
			<div class="prizes">
				<MPrizeFund
					v-if="isPlayerFreeSpin"
					class="grand"
					variant="entries"
					img="/nuxt-img/prizes/freespins.png"
					:icon-size="24"
				>
					<AText :size="32" :modifiers="['bold', 'uppercase']" class="free-spin text-tlalnepantla">
						{{ freeSpins.spinsRemain }}
					</AText>
					<template #after>
						<AText variant="toledo" :modifiers="['bold', 'uppercase']" class="free-spin text-tlalnepantla">
							<i18n-t keypath="dailyLogin.promo.free">
								<template #br>
									<br />
								</template>
							</i18n-t>
						</AText>
					</template>
				</MPrizeFund>

				<template v-else-if="!isCompleted">
					<MPrizeFund v-if="currentPrize?.coins" variant="coins" img="/nuxt-img/prizes/funcoins@2x.png" :icon-size="24">
						<AText variant="tanzay" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase']">
							{{ numberFormat(currentPrize.coins) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund
						v-if="currentPrize?.entries"
						variant="entries"
						img="/nuxt-img/prizes/citycoins@2x.png"
						:icon-size="24"
					>
						<AText variant="tanzay" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase']">
							{{ numberFormat(currentPrize.entries) }}
						</AText>
						<AText variant="ternopil" class="count-after" :modifiers="['bold', 'uppercase']">
							{{ t("Free City Coins") }}
						</AText>
					</MPrizeFund>
				</template>
				<div v-else class="grand-prize">
					<div class="coins">
						<MPrizeFund
							v-if="totalPrizes?.coins"
							variant="coins"
							img="/nuxt-img/prizes/funcoins@2x.png"
							:icon-size="22"
						>
							<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['bold', 'uppercase']">
								{{ numberFormat(totalPrizes.coins) }}
							</AText>
						</MPrizeFund>
						<MPrizeFund
							v-if="totalPrizes?.entries"
							variant="entries"
							img="/nuxt-img/prizes/citycoins@2x.png"
							:icon-size="22"
						>
							<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['bold', 'uppercase']">
								{{ numberFormat(totalPrizes.entries) }}
							</AText>
						</MPrizeFund>
					</div>
					<MPrizeFund
						v-if="totalPrizes?.freeSpinCount"
						variant="entries"
						img="/nuxt-img/prizes/freespins.png"
						:icon-size="22"
					>
						<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['bold', 'uppercase']">
							<i18n-t keypath="{key} Free Plays">
								<template #key>{{ numberFormat(totalPrizes.freeSpinCount) }}</template>
							</i18n-t>
						</AText>
					</MPrizeFund>
				</div>
			</div>
			<template v-if="isPlayerFreeSpin">
				<AText class="game-title" variant="topeka" :modifiers="['semibold']">
					<i18n-t keypath="for {key}">
						<template #key>
							<span v-html="freeSpinGame?.title || ''" />
						</template>
					</i18n-t>
				</AText>
				<AButton variant="primary" class="button" size="s">
					<AText :modifiers="['semibold']" class="text-tlalnepantla">
						{{ t("Play Now") }}
					</AText>
				</AButton>
			</template>
			<AButton v-else-if="isAvailable" variant="primary" class="button" size="s">
				<AText :modifiers="['semibold']" class="text-tlalnepantla">
					{{ t("Claim Reward") }}
				</AText>
			</AButton>
		</div>
		<MDailyLoginLevels
			v-if="!isPlayerFreeSpin"
			:levels="levels"
			:active="activeLevel"
			:time-end="durationLeft"
			:is-available="isAvailable"
			:is-completed="!!isCompleted"
			:is-last-prize="isLastPrize"
		/>
	</ACard>
</template>

<style lang="scss" scoped>
.card-daily {
	padding: 16px;
	justify-content: center;
	gap: 8px;
	background: var(--gyeongju);

	&.completed {
		background: var(--gimcheon);
		justify-content: center;
	}

	&.lastDay {
		justify-content: space-around;

		.top > .button {
			margin-top: 12px;
		}
	}

	.title {
		max-width: 136px;
	}

	.subtitle {
		max-width: 170px;
	}

	&:deep(.prize) {
		gap: 4px;
	}

	&:deep(.levels-grid) {
		width: 80%;
	}

	.text-count {
		text-shadow: 0 4px 4px var(--coronel);
	}

	.button {
		min-width: 140px;
		width: max-content;
	}

	.top {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.free {
		position: absolute;
		right: 12px;
		top: 0;
		width: 60px;
		height: 49px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 3px 16px 0;

		&:deep(svg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&-text {
			position: relative;
			z-index: 2;
		}
	}

	.prizes {
		display: flex;
		flex-direction: column;
	}

	.count-after {
		width: 60px;
	}

	.grand {
		display: flex;
		align-items: center;
		gap: 4px;
		margin-bottom: 8px;

		.icon {
			width: 24px;
			height: 24px;
		}

		.free-spin {
			text-shadow: 0 4px 4px var(--coronel);
			line-height: 18px;
		}
	}

	.game-title {
		max-width: 160px;
		margin-bottom: 16px;
	}

	.free-game {
		position: absolute;
		top: 90px;
		right: 10px;
		width: 100px;
		height: auto;
		border-radius: 8px;
		border: 1px solid var(--сirebon);
		z-index: 9;
	}

	.grand-prize {
		.count,
		.coins {
			display: flex;
			align-items: center;
			gap: 4px;
			text-shadow: 0 4px 4px var(--coronel);
		}

		.spin {
			margin-bottom: 12px;
		}

		.icon {
			width: 22px;
			height: 22px;
		}

		.label-time {
			margin-top: 10px;
		}
	}
}
</style>
